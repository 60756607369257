<template>
  <div class="places-search-div">
    <slot>
      <input
        v-model="location"
        type="text"
        :placeholder="placeholder || 'Search'"
        class="form-control"
        :aria-expanded="places.length > 0 ? true : false"
        aria-controls="places-dropdown"
        @keyup="search"
      >

      <div
        v-if="formLocationError"
        class="text-danger"
      >
        {{ locationErrorText }}
      </div>

    </slot>
    <ul
      v-if="places.length > 0"
      v-click-outside="resetPlace"
      role="menu"
      :class="['places-dropdown']"
      class="places-menu"
    >
      <li
        v-for="place in places"
        :key="place.place_id"
        role="menuitem"
        class="place"
        @click="selectPlace(place)"
      >
        {{ place.description }}
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Please start typing and select the correct address from the suggestions',
    },
    locationError: {
      type: Boolean,
      default: false,
    },
    initialAddress: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: `places-search-${Math.floor(Math.random() * 100)}`,
    },
  },
  data() {
    return {
      location: this.initialAddress ? this.initialAddress : '',
      places: [],
      selected: {},
      service: null,
      formLocationError: false,
      locationErrorText: 'Please start typing and select the correct address from the suggestions',
      defaultErrorMessage: 'Please start typing and select the correct address from the suggestions',
    }
  },
  //   metaInfo() {
  //     return {
  //       script: [
  //         {
  //           src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCnpNoSCz5YBcJem2z4ihqYBHxv4m_zQS8&libraries=places`,
  //           async: true,
  //           defer: true,
  //         },
  //       ],
  //     }
  //   },
  watch: {
    location() {
      if (this.service === null) {
        this.MapsInit()
      }
      if (this.location.length === 0) {
        this.clearForm()
      }
    },
    locationError(newValue) {
      this.formLocationError = newValue
    },
  },
  methods: {
    MapsInit() {
      this.service = new this.$googleMap.maps.places.AutocompleteService()
    },
    async search() {
      if (!this.location) {
        this.formLocationError = true
        this.locationErrorText = this.defaultErrorMessage
        return
      }
      this.formLocationError = false

      try {
        const results = await this.service.getPlacePredictions({
          input: this.location,
          // types: ['(regions)'],
          // componentRestrictions: {
          //   country: 'us',
          // },
        })
        this.places = results.predictions
      } catch (error) {
        this.formLocationError = true
        this.locationErrorText = error
      }
    },
    getLatLong(location) {
      try {
        const self = this
        const geocoder = new this.$googleMap.maps.Geocoder()
        geocoder.geocode({
          placeId: location,
        }, async (results, status) => {
          if (status === this.$googleMap.maps.GeocoderStatus.OK) {
            self.$emit('selectedArea', {
              description: results[0].formatted_address,
              location_id: results[0].place_id,
              lat: await results[0].geometry.location.lat(),
              lon: await results[0].geometry.location.lng(),
            })
          }
        })
      } catch (error) {
        console.log(`Error getting LatLon coords: ${error}`)
      }
    },
    selectPlace(place) {
      this.location = place.description
      this.selected.description = place.description
      this.selected.location_id = place.place_id
      this.getLatLong(place.place_id)
      this.places = []
    },
    resetPlace() {
      this.formLocationError = true
      this.locationErrorText = this.defaultErrorMessage
      this.places = []
      this.$emit('selectedArea', {})
    },
    clearForm() {
      this.location = ''
      this.selected = {}
      this.places = []
      this.$emit('selectedArea', {})
    },
  },
}
</script>

<style lang="scss" scoped>

.places-search-div {
  input {
    width: 100% !important;
  }
  .places-menu {
    list-style: none;
    width: 97%;
    padding: 5px;
    position: absolute;
    z-index: 9999999;
    background-color: #fff;
    border: 1px solid #cccccc;
    li {
      padding: 3px;
      margin-bottom: 10px;
      &:hover {
        background-color: #cccccc;
        cursor: pointer;
      }
    }
  }

}
</style>
