<template>

  <b-row>
    <b-col>
      <b-card>
        <!-- Header -->
        <div
          v-if="!loading"
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5
            v-if="editing && editing.id"
            class="mb-0"
          >
            Edit Service Locator
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            Add New Service Locator
          </h5>
        </div>
        <div
          v-if="loading"
          class="d-flex justify-content-center mb-1 mt-5"
        >
          <b-spinner label="Loading..." />
        </div>
        <!-- BODY -->
        <validation-observer
          v-else
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="validateForm"
          >
            <b-row>

              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Name"
                  rules="required|max:191"
                >
                  <b-form-group
                    label="Name"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="serviceLocatorData.name"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Phone"
                  :rules="{required:true, regex:/(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/, max:20}"
                >
                  <b-form-group
                    label="Phone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="serviceLocatorData.phone"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Country"
                  rules="required|max:191"
                >
                  <b-form-group
                    label="Country"
                    label-for="country"
                  >

                    <v-select
                      v-model="serviceLocatorData.country"                     
                      id="country"
                      :options="countriesOptions"
                      :selectable="option => ! option.text.includes('select_value')"
                      :classname="( serviceLocatorData && (!serviceLocatorData.country)) ? 'form-control is-invalid' : 'form-control'"
                      label="text"
                      value="value"
                      :state="validationContext.errors.length > 0 ? false:null"
                      @input="onCountryCodeChange($event)"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Country Code"
                  rules="required|max:191"
                >
                  <b-form-group
                    label="Country Code"
                    label-for="country-code"
                  >
                    <b-form-input
                      id="country-code"
                      v-model="countryCode"
                      disabled
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="8">
                <validation-provider
                  #default="validationContext"
                  name="address"
                >
                  <b-form-group
                    label="Address"
                    label-for="address"
                  >
                    <google-places-search
                      :location-error="locationError"
                      :initial-address="serviceLocatorData.address ? serviceLocatorData.address : null"
                      @selectedArea="getAddressData"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="type of service"
                  :rules="{required}"
                >
                  <b-form-group
                    label="Type Of Service"
                    label-for="type_of_service"
                  >
                    <b-form-select
                      v-model="serviceLocatorData.service_type"
                      label="type_of_service"
                      :options="typeOfServiceOptions"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="status"
                  rules="required|max:191"
                >
                  <b-form-group
                    label="Status"
                    label-for="status"
                  >
                    <b-form-select
                      v-model="serviceLocatorData.is_active"
                      label="status"
                      :options="statusOptions"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  vid="mavenoid_flow"
                  name="mavenoid flow"
                  rules="required|max:191"
                >
                  <b-form-group
                    label="Mavenoid Flow Key"
                    label-for="mavenoid_flow"
                  >
                    <b-form-input
                      id="mavenoid_flow"
                      v-model="serviceLocatorData.mavenoid_flow"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <label class="d-block w-100 pl-1">Working Hours</label>
              <small class="text-muted  w-100 pl-1 mb-1">(using 24hour clock, simply type From “09:00” to “17:00” for 09:00 & 17:00 to appear)</small>
              <b-col
                xs="12"
                sm="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="saturday"
                  :rules="time_rules"
                >
                  <b-form-group
                    label="Saturday"
                    label-for="saturday"
                  >
                    <b-form-input
                      id="saturday"
                      v-model="serviceLocatorData.working_hours.saturday"
                      v-mask="time_mask"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                xs="12"
                sm="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="sunday"
                  :rules="time_rules"
                >
                  <b-form-group
                    label="Sunday"
                    label-for="sunday"
                  >
                    <b-form-input
                      id="sunday"
                      v-model="serviceLocatorData.working_hours.sunday"
                      v-mask="time_mask"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                xs="12"
                sm="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="monday"
                  :rules="time_rules"
                >
                  <b-form-group
                    label="Monday"
                    label-for="monday"
                  >
                    <b-form-input
                      id="monday"
                      v-model="serviceLocatorData.working_hours.monday"
                      v-mask="time_mask"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                xs="12"
                sm="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="tuesday"
                  :rules="time_rules"
                >
                  <b-form-group
                    label="Tuesday"
                    label-for="tuesday"
                  >
                    <b-form-input
                      id="tuesday"
                      v-model="serviceLocatorData.working_hours.tuesday"
                      v-mask="time_mask"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                xs="12"
                sm="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="wednesday"
                  :rules="time_rules"
                >
                  <b-form-group
                    label="Wednesday"
                    label-for="wednesday"
                  >
                    <b-form-input
                      id="wednesday"
                      v-model="serviceLocatorData.working_hours.wednesday"
                      v-mask="time_mask"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                xs="12"
                sm="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="thursday"
                  :rules="time_rules"
                >
                  <b-form-group
                    label="Thursday"
                    label-for="thursday"
                  >
                    <b-form-input
                      id="thursday"
                      v-model="serviceLocatorData.working_hours.thursday"
                      v-mask="time_mask"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                xs="12"
                sm="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="friday"
                  :rules="time_rules"
                >
                  <b-form-group
                    label="Friday"
                    label-for="friday"
                  >
                    <b-form-input
                      id="friday"
                      v-model="serviceLocatorData.working_hours.friday"
                      v-mask="time_mask"
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr>

            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  name="Savvycal URL"
                  rules="max:191"
                >
                  <b-form-group
                    label="Savvycal URL"
                    label-for="savvy_workflow"
                  >
                    <b-form-input
                      id="savvy_workflow"
                      v-model="serviceLocatorData.savvy_workflow"
                      placeholder=""
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  name="Test drive URL"
                  rules="max:191"
                >
                  <b-form-group
                    label="Test drive URL"
                    label-for="test_drive_url"
                  >
                    <b-form-input
                      id="test_drive_url"
                      v-model="serviceLocatorData.test_drive_url"
                      placeholder=""
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  name="description"
                  rules="required|max:191"
                >
                  <b-form-group
                    label="Description (Please ensure spelling and message is correct as this message will be public facing.)"
                    label-for="description"
                  >
                    <b-form-textarea
                      id="description"
                      v-model="serviceLocatorData.description"
                      placeholder=""
                      :state="validationContext.errors.length > 0 ? false:null"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-if="editing && editing.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Update
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="$router.push({ name:'service-locators' })"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, max, min,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import googlePlacesSearch from './googlePlacesSearch.vue'

export default {
  components: {
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    googlePlacesSearch,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      locationError: false,
      editing: null,
      time_mask: 'from ##:## to ##:##',
      time_rules: 'min:19|max:19',
      loading: true,
      countryCode: null,
      serviceLocatorOriginalData: null,
      serviceLocatorData: {
        service_type: null,
        google_maps: null,
        countryCode: null,
        repair_url: null,
        test_drive_url: null,
        savvy_workflow: null,
        country: null,
        name: null,
        lat: null,
        lng: null,
        phone: null,
        address: null,
        is_active: 1,
        working_hours: {
          saturday: null,
          sunday: null,
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
        },
      },
      typeOfServiceOptions: [
        { value: 'hub', text: 'Apollo Hub' },
        { value: 'cert', text: 'Certified Shop' },
        { value: 'store', text: 'Store' },
      ],
      statusOptions: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' },
      ],
      countriesOptions: [],
      max,
      min,
      required,
    }
  },
  mounted() {
    this.$http.get(this.$resources.configurations.countries).then(countriesResponse => {
      this.countriesOptions = countriesResponse.data.data.countries.map(country => ({ value: country.id, text: country.name, code: country.phone_prefix }))

      if (this.$route.params.id) {
        this.editing = {
          id: this.$route.params.id,
        }

        this.$http.get(this.$resources.serviceLocators.fetch.replace(':id', this.editing.id)).then(response => {
          const { data: { status, data } } = response
          if (status === 200) {
            this.serviceLocatorData = { ...data.service }
            this.serviceLocatorData.country = this.countriesOptions.find(country => country.value === this.serviceLocatorData.country_id)
            this.serviceLocatorData.countryCode = this.serviceLocatorData.country.code
            this.countryCode = this.serviceLocatorData.country.code
            if (!this.serviceLocatorData.working_hours) {
              this.serviceLocatorData.working_hours = {
                saturday: null,
                sunday: null,
                monday: null,
                tuesday: null,
                wednesday: null,
                thursday: null,
                friday: null,
              }
            }
            this.serviceLocatorOriginalData = { ...this.serviceLocatorData }
            this.loading = false
          }
        })
      } else {
        this.editing = null
        this.loading = false
      }
    })
  },
  methods: {
    onCountryCodeChange(event) {
      this.countryCode = event.code
    },
    resetForm() {
      const emptyFormData = {
        google_maps: null,
        countryCode: null,
        service_type: null,
        country: null,
        name: null,
        lat: null,
        lng: null,
        phone: null,
        address: null,
        repair_url: null,
        test_drive_url: null,
        savvy_workflow: null,
        is_active: 1,
        working_hours: {
          saturday: null,
          sunday: null,
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
        },
      }
      if (this.serviceLocatorOriginalData) {
        this.serviceLocatorData = { ...this.serviceLocatorOriginalData }
      } else {
        this.serviceLocatorData = { ...emptyFormData }
      }
      this.$refs.address.update(this.serviceLocatorData.address)
    },
    getAddressData(addressData) {
      if (Object.keys(addressData).length > 0) {
        this.serviceLocatorData.address = addressData.description
        this.serviceLocatorData.lat = addressData.lat
        this.serviceLocatorData.lng = addressData.lon
      } else {
        this.serviceLocatorData.address = null
        this.serviceLocatorData.lat = null
        this.serviceLocatorData.lng = null
        this.$refs.refFormObserver.setErrors({ address: ['invalid Address'] })
      }
    },
    checkAddressData() {
      this.serviceLocatorData.lat = ''
      this.serviceLocatorData.lng = ''
    },
    validateForm() {
      if (this.serviceLocatorData.address === undefined || this.serviceLocatorData.address === null) {
        this.locationError = true
        this.$refs.refFormObserver.setErrors({ address: ['invalid Address'] })
      } else {
        this.locationError = false
      }

      this.$refs.refFormObserver.validate().then(success => {
        if (success && !this.locationError) {
          this.loading = true
          this.$http.post(this.editing && this.editing.id ? this.$resources.serviceLocators.update.replace(':id', this.editing.id) : this.$resources.serviceLocators.store, {
            ...this.serviceLocatorData,
            countryCode: this.countryCode,
            country_id: this.serviceLocatorData.country.value,
          }).then(response => {
            const { data: { status } } = response

            switch (status) {
              case 200:
                this.$router.push({ name: 'service-locators' })
                break
              default:
                this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
            }
            this.loading = false
          }).catch((error) => {
            console.log(error.response.data.error)
            this.$refs.refFormObserver.setErrors(error.response.data.error)
            this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.travel-map {
  height: 400px;
}
</style>
